import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';

import { Metadata } from '@latitude/metadata';
import { Box } from '@latitude/box';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import { Link } from '@latitude/link';
import Section from '@latitude/section';
import { BREAKPOINT, COLOR } from '@latitude/core/utils/constants';

import Layout from '@/components/layout';
import PageHeaderSection from '@/components/PageLayout/PageHeaderSection';
import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';

import { InstalmentInterestFreeCalculatorAssignedMinimums } from './_instalmentInterestFreeCalculator';
import pageHeroImage from './hero-harveynorman-calc.webp';

const ANNUAL_FEE = 65;
const ESTABLISHMENT_FEE = 55;
const MINIMUM_AMOUNT = 50;
const MONTHLY_FEE = 0;
const MONTHLY_TERMS = [
  { duration: 12, minFee: 0, maxAmount: 1200 },
  { duration: 18, minFee: 130, maxAmount: 1800 },
  { duration: 24, minFee: 130, maxAmount: 2400 },
  { duration: 36, minFee: 500 },
  { duration: 48, minFee: 490 },
  { duration: 50, minFee: 500 },
  { duration: 60, minFee: 1500 }
];

const DEFAULT_APPLY_URL =
  'https://cards.gemvisa.co.nz/?cardType=gemvisanz&gemid2=' +
  encodeURIComponent(
    'gemf-web:information:credit-cards:harvey-norman:apply-now:web'
  );
const INSTORE_APPLY_URL =
  'https://cards.gemvisa.co.nz/?merchantID=090685742&channel=Instore&source=0&stream=Upstream&gemid2=' +
  encodeURIComponent(
    'gemf-web:information:credit-cards:harvey-norman:apply-now:instore'
  );

function HarveyNormanCalculatorPage({ location }) {
  const [applyUrl, setApplyUrl] = useState(DEFAULT_APPLY_URL);
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    if (queryParams?.source === 'instore') {
      setApplyUrl(INSTORE_APPLY_URL);
    } else {
      setApplyUrl(DEFAULT_APPLY_URL);
    }
  }, []);

  return (
    <Layout location={location}>
      <main className="navigation-spacer">
        <Metadata
          title="Shop Interest Free at Harvey Norman | Gem Finance"
          description="Calculate repayments for Gem Visa purchases on an instalment interest free promotional plan. T&amp;Cs &amp; fees apply."
          canonical={`${location.protocol}//${location.host}${location.pathname}`}
        />

        <SecondBar>
          <div
            css={{
              maxWidth: '1140px',
              width: '100%',
              margin: '0 auto'
            }}
          >
            <CtaButton
              button="primary"
              width="fit-content"
              href={applyUrl}
              target="_self"
            >
              Apply Now
            </CtaButton>
          </div>
        </SecondBar>
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />
          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <PageHeaderSection
              title="Gem Visa Instalment Interest Free Calculator"
              subTitle="Calculate payments for purchases on instalment Interest Free plans using a participating Gem credit card."
              pageImage={pageHeroImage}
              pageImageAlt="Harvey Norman Gem Card"
            />
          )}
          <Section
            className="bg-lightest"
            css={`
              && {
                select,
                input {
                  background-color: #fff !important;
                }

                div.container.section__content
                  > [class^='InstalmentInterestFreeCalculatorAssignedMinimums___StyledDiv']
                  > p[class^='css-1'] {
                  display: none;
                }
              }
            `}
          >
            <InstalmentInterestFreeCalculatorAssignedMinimums
              heading="Your Instalment Interest Free payment plan"
              monthlyFeeText="Excluding all fees"
              annualFeeText="Total amount paid includes $55 establishment fee and $65 annual account fee (charged $32.50 half-yearly)."
              annualFee={ANNUAL_FEE}
              establishmentFee={ESTABLISHMENT_FEE}
              minimumAmount={MINIMUM_AMOUNT}
              monthlyFee={MONTHLY_FEE}
              monthlyTerms={MONTHLY_TERMS}
              importantInfo={[
                'Credit and lending criteria and fees, including a $55 establishment fee and a $65 annual fee (charged $32.50 half-yearly) apply. Prevailing interest rate (currently 29.49% p.a.) applies to any remaining balance on the expiry of the interest free term. For cash advances, an interest rate of 29.95% p.a. and a $2 fee applies. Further information on rates and fees can be found at gemfinance.co.nz.',
                'Instalment Interest Free (Equal Monthly Payments) means that you will pay the same amount every month for the promotion period. The estimated repayments quoted are indicative only and are calculated based on the nominated purchase amount and instalment interest free period only. They do not take into account other purchases or balances or any other fees that may apply. Your repayments will be due on a monthly basis and the exact amounts due will be detailed on your monthly statement.',
                'To be eligible for an Instalment Interest Free plan, a minimum finance amount will apply. See current promotions for terms and conditions.',
                'This calculator is current as of 12 June 2024 and may be updated from time to time.',
                'Instalment Interest Free promotions, their terms and minimum payments required are subject to change over time.',
                'Please refer to Gem Visa Conditions of Use for further information about Instalment Plans. Gem Visa is provided by Latitude Financial Services Limited.'
              ]}
            />
          </Section>
        </Box>
      </main>
    </Layout>
  );
}

const SecondBar = styled.div`
  && {
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 0px;
    height: 48px !important;
    padding: 5px 10px;
    top: 80px;
    position: sticky;
    z-index: 3;

    @media (max-width: ${BREAKPOINT.LG}) {
      top: 56px;
    }
  }
`;

const CtaButton = styled(Link)`
  height: fit-content;
  width: fit-content;
  padding: 5pt 15pt;
  float: right;
`;

export default HarveyNormanCalculatorPage;
